import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Portfolio from '../components/portfolio'
import lang from '../data/es.json'

const PortFolioEsPage = ({ location, data }) => {
  const {
    mainProjectsData,
    secondaryProjectsData,
    allImageSharp,
    logo,
  } = data
  const projects = mainProjectsData.nodes.map(node => node.context.projectData)
  const secondaryProjects = secondaryProjectsData.nodes.map(node => node.context.projectData)

  return (
    <Layout
      lang={lang}
      location={location}
      isProjects
      projects={projects}
      secondaryProjects={secondaryProjects}
      images={allImageSharp.nodes.map(node => node.sizes)}
      logo={logo.sizes}
      component={Portfolio}
    />
  )
}

export default PortFolioEsPage

export const pageQuery = graphql`
  query PortfolioEsQuery {
    mainProjectsData: allSitePage(
      sort: { order: ASC, fields: context___projectData___index }
      filter: {
        component: { glob: "**/project.es.js" }
        context: { projectData: { index: { lt: 3 } } }
      }
    ) {
      nodes {
        path
        context {
          projectData {
            name {
              fullName
            }
            caption
            image
            path
          }
        }
      }
    }

    secondaryProjectsData: allSitePage(
      sort: { order: ASC, fields: context___projectData___index }
      filter: {
        component: { glob: "**/project.es.js" }
        context: { projectData: { index: { gte: 3 } } }
      }
    ) {
      nodes {
        path
        context {
          projectData {
            name {
              fullName
            }
            caption
            image
            path
          }
        }
      }
    }

    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }

    allImageSharp(
      filter: { fluid: { originalName: { glob: "{bg,portfolio-,portfoliomobile-,bg}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
  }
`
